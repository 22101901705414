<template>
  <div class="elv-common-components-rule-table-container">
    <el-table
      ref="tableElRef"
      v-loading.lock="props.loading"
      :row-key="rowKey"
      :data="listData"
      style="width: 100%"
      stripe
      height="100%"
      lazy
      :expand-row-keys="expandedRowKeys"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      border
      class="elv-automation-table"
    >
      <el-table-column
        v-if="!['businessData', 'valuation'].includes(props.activeTab)"
        :label="t('common.priority')"
        width="85"
        class-name="none-padding"
      >
        <template #default="{ row }">
          <div
            v-if="!(props.activeTab === 'transfer' && row?.children !== undefined)"
            class="elv-automation-table-row__cell-item"
            :class="{ 'is-system': row?.systemAutomationRuleId }"
          >
            <PriorityCell :row="row" />
          </div>
          <div
            v-if="props.activeTab === 'transfer' && row?.automationRuleId === 'System' && row?.children !== undefined"
            class="elv-automation-table-row__cell-item is-expanded"
          >
            <SvgIcon
              :name="expandedRowKeys.includes('System') ? 'eye' : 'eye-off-line'"
              width="18"
              height="18"
              @click="onViewSystemRule"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column v-else type="index" width="36" align="center">
        <template #default="{ row }">
          <div class="elv-automation-table-row__cell-item">
            <span class="elv-automation-table-row__cell-index">{{
              row.rowIndex + 1 + (props.ruleParams.page - 1) * props.ruleParams.limit
            }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" :label="t('title.ruleName')" width="190">
        <template #default="{ row }">
          <div class="elv-automation-table-row__cell-item">
            <span class="elv-automation-table-row__cell-name"
              >{{ row.name }}
              <template
                v-if="
                  props.activeTab === 'transfer' && row?.automationRuleId === 'System' && row?.children !== undefined
                "
                >({{ row?.children?.length }})</template
              ></span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="['businessData', 'valuation'].includes(props.activeTab)"
        prop="name"
        :label="t('common.dataName')"
        width="150"
      >
        <template #default="{ row }">
          <div class="elv-automation-table-row__cell-item">
            <span class="elv-automation-table-row__cell-data"> {{ row?.businessDataType?.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="t('common.conditions')" min-width="260">
        <template #default="{ row }">
          <div class="elv-automation-table-row__cell-item">
            <ConditionCell :params="{ data: row }" :active-tab="props.activeTab" />
          </div>
        </template>
      </el-table-column>
      <template v-if="!['valuation', 'counterpartyTransformer'].includes(props.activeTab)">
        <el-table-column :label="t('report.actionJournal')" width="240">
          <template #default="{ row }">
            <template v-if="!(props.activeTab === 'transfer' && row?.children !== undefined)">
              <div class="elv-automation-table-row__cell-item">
                <ActionJournalCell :params="{ data: row }" :active-tab="props.activeTab" />
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!['transfer', 'counterpartyTransformer'].includes(props.activeTab)"
          :label="t('report.actionEntry')"
          width="329"
        >
          <template #default="{ row }">
            <div v-for="(entry, index) in row.entryActions" :key="index" class="elv-automation-table-row__cell-item">
              <ActionEntryCell
                :active-tab="props.activeTab"
                :entry-index="index"
                :params="{
                  data: {
                    ...entry,
                    entryActions: row.entryActions,
                    businessDataType: row.businessDataType,
                    derivativeType: row?.derivativeType ?? '',
                    journalType: row.journalType
                  }
                }"
              />
            </div>
          </template>
        </el-table-column>
      </template>
      <el-table-column v-if="props.activeTab === 'valuation'" :label="t('report.actionCreateData')" width="240">
        <template #default="{ row }">
          <div class="elv-automation-table-row__cell-item is-valuation">
            <span class="elv-automation-table-row__cell-action__label">{{ t('common.table') }}</span>
            <p class="elv-automation-table-cell-conditions__item-is">
              <span>{{ t('common.is') }}</span>
            </p>
            <div class="elv-automation-table-cell-conditions__item-value">
              {{
                row?.derivativeType === 'FUTURE'
                  ? 'Future-Unrealized P&L Adjustment Raw Data'
                  : 'Options-Unrealized P&L Adjustment Raw Data'
              }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="props.activeTab === 'counterpartyTransformer'"
        :label="t('report.actionIdentifyCounterparty')"
        width="460"
      >
        <template #default="{ row }">
          <div class="elv-automation-table-row__cell-item is-counterparty">
            <div>
              <div class="elv-automation-table-cell-conditions__item-value">
                {{ t('report.identifyCounterparty') }}
              </div>
            </div>
            <div>
              <span class="elv-automation-table-row__cell-action__label">Based on:&nbsp;&nbsp;&nbsp;</span>
              <div class="elv-automation-table-cell-conditions__item-value">
                {{ row?.counterpartyField === 'IDENTIFICATION_CODE' ? 'Memo_Identification code' : 'Memo_Account' }}
              </div>
            </div>
            <div v-if="row?.type !== 'SYSTEM'">
              <span class="elv-automation-table-row__cell-action__label">Regular Expression:&nbsp;&nbsp;&nbsp;</span>
              <div class="elv-automation-table-cell-conditions__item-value">
                {{ row?.extractRegex }}
              </div>
            </div>
            <div v-if="row?.identificationCodeName">
              <span class="elv-automation-table-row__cell-action__label">Match vs &nbsp;&nbsp;&nbsp;</span>
              <div class="elv-automation-table-cell-conditions__item-value">
                {{ row?.identificationCodeName }}
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="t('common.status')" :resizable="false" width="120" class-name="is-center-label">
        <template #default="{ row }">
          <div
            v-if="!(props.activeTab === 'transfer' && row?.children !== undefined)"
            class="elv-automation-table-row__cell-switch"
          >
            <el-switch
              v-model="row.enabled"
              :disabled="row?.type === 'SYSTEM' && props.activeTab === 'counterpartyTransformer'"
              @change="onChangeStatus(row, $event as boolean)"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column :resizable="false" width="120">
        <template #default="{ row }">
          <div
            v-if="
              !(props.activeTab === 'transfer' && row?.children !== undefined) &&
              !row?.systemAutomationRuleId &&
              row?.type !== 'SYSTEM'
            "
            class="elv-automation-table-row__cell-button"
          >
            <template v-if="['businessData', 'valuation'].includes(props.activeTab)">
              <SvgIcon
                v-if="
                  row.status === 'FINISHED' &&
                  (!row?.businessDataType?.deletedAt || row?.businessDataType?.status !== 'DELETING')
                "
                name="play-outline"
                width="16"
                height="16"
                @click="onExecuteRule(row)"
              />
              <img
                v-else-if="!row?.businessDataType?.deletedAt || row?.businessDataType?.status !== 'DELETING'"
                src="@/assets/img/reports/sources-sync-loading.png"
                alt="loading"
              />
            </template>
            <SvgIcon
              name="sources-edit"
              :class="{ 'is-disabled': row?.status === 'DELETING' }"
              width="18"
              height="18"
              @click="onEditRule(row)"
            />
            <SvgIcon
              name="sources-delete"
              :class="{ 'is-disabled': row?.status === 'DELETING' }"
              width="18"
              height="18"
              @click="onOpenDeleteConfirm(row)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <elv-pagination
    class="elv-journals-list-pagination"
    size-popper-class="elv-journals-pagination__sizes-popper"
    :limit="props.ruleParams.limit"
    :current-page="props.ruleParams.page"
    :total="totalCount"
    @current-change="onChangePage"
    @size-change="onChangePageSize"
  />
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import PriorityCell from './Cell/PriorityCell.vue'
import ConditionCell from './Cell/ConditionCell.vue'
import ActionEntryCell from './Cell/ActionEntryCell.vue'
import ActionJournalCell from './Cell/ActionJournalCell.vue'
import { useValuationStore } from '@/stores/modules/valuation'
import { useTransactionStore } from '@/stores/modules/transactions'

const props = defineProps({
  ruleParams: {
    type: Object,
    required: true
  },
  activeTab: {
    type: String,
    default: 'transfer'
  },
  loading: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()
const valuationStore = useValuationStore()
const transactionStore = useTransactionStore()

const tableElRef = useTemplateRef('tableElRef')
const expandedRowKeys = ref(['Custom'])

const emit = defineEmits([
  'onChangeStatus',
  'onEditRule',
  'onExecuteRule',
  'onOpenDeleteConfirm',
  'onChangePage',
  'onChangePageSize',
  'getAutomationRuleList'
])

const totalCount = computed(() => {
  switch (props.activeTab) {
    case 'transfer':
      return transactionStore.automationRuleList.totalCount
    case 'trade':
      return transactionStore.tradeAutomationRuleList.totalCount
    case 'gainLoss':
      return transactionStore.gainLossAutomationRuleList.totalCount
    case 'businessData':
      return transactionStore.businessDataAutomationRuleList.totalCount
    case 'valuation':
      return transactionStore.derivativeFactTransformerList.totalCount
    case 'derivativeAdjustment':
      return valuationStore.derivativeAdjustmentAutomationRuleList.totalCount
    case 'counterpartyTransformer':
      return transactionStore.counterpartyTransformerList.totalCount
    default:
      break
  }
  return 0
})

const rowKey = computed(() => {
  switch (props.activeTab) {
    case 'businessData':
      return 'businessDataAutomationRuleId'
    case 'transfer':
      return 'automationRuleId'
    case 'trade':
      return 'tradeAutomationRuleId'
    case 'gainLoss':
      return 'gainLossAutomationRuleId'
    case 'valuation':
      return 'derivativeFactTransformerId'
    default:
      break
  }
  return 'automationRuleId'
})

const listData = computed(() => {
  switch (props.activeTab) {
    case 'transfer':
      return [
        {
          name: 'System rule',
          hasChildren: true,
          automationRuleId: 'System',
          children: transactionStore.systemAutomationRuleList.map((item: any, index: number) => {
            return {
              automationRuleId: `${index}${item?.systemAutomationRuleId}`,
              ...item
            }
          })
        },
        {
          name: 'Custom Automation Rule',
          automationRuleId: 'Custom',
          children: transactionStore.automationRuleList.list
        }
      ]
    case 'trade':
      return transactionStore.tradeAutomationRuleList.list
    case 'gainLoss':
      return transactionStore.gainLossAutomationRuleList.list
    case 'businessData':
      return transactionStore.businessDataAutomationRuleList.list.map((item: any, index: number) => {
        return {
          rowIndex: index,
          ...item
        }
      })
    case 'valuation':
      return transactionStore.derivativeFactTransformerList.list.map((item: any, index: number) => {
        return {
          rowIndex: index,
          ...item
        }
      })
    case 'derivativeAdjustment':
      return valuationStore.derivativeAdjustmentAutomationRuleList.list
    case 'counterpartyTransformer':
      return transactionStore.counterpartyTransformerList.list
    default:
      break
  }
  return []
})

const onViewSystemRule = () => {
  if (expandedRowKeys.value.includes('System')) {
    expandedRowKeys.value = expandedRowKeys.value.filter((item) => item !== 'System')
  } else {
    expandedRowKeys.value.push('System')
  }
}

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const onEditRule = (row: any) => {
  if (row?.status === 'DELETING') {
    return
  }
  emit('onEditRule', row)
}

const onOpenDeleteConfirm = (row: any) => {
  if (row?.status === 'DELETING') {
    return
  }
  emit('onOpenDeleteConfirm', row)
}

const onChangeStatus = (row: any, status: boolean) => {
  emit('onChangeStatus', row, status)
}

const onExecuteRule = (row: any) => {
  emit('onExecuteRule', row)
}

defineExpose({
  tableElRef
})
</script>

<style lang="scss" scoped>
.elv-common-components-rule-table-container {
  height: calc(100% - 54px);
  position: relative;
}
</style>
<style lang="scss">
.elv-automation-table.el-table {
  --el-table-border-color: #edf0f3;

  .el-table__header {
    .el-table__cell {
      height: 36px;
      padding: 0;
      background: #eef4fb;
      border-bottom-color: #ced7e0;
      border-right-color: #e4e7eb;

      &.is-center-label {
        .cell {
          justify-content: center;
        }
      }

      .cell {
        padding: 0 10px;
        height: 35px;
        display: flex;
        align-items: center;
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
      }
    }
  }

  &.el-table--border {
    &::after,
    &::before {
      display: none;
    }

    .el-table__inner-wrapper {
      &::after {
        background-color: #ced7e0;
      }
    }
  }

  .el-table__border-left-patch {
    display: none;
  }

  .el-switch {
    --el-switch-on-color: #2f63eb;
    --el-switch-off-color: #dde1e6;
    width: 38px;
    height: 20px;
    border-radius: 20px;
  }

  .el-table__row {
    &.el-table__row--level-0 {
      background: #fbf9f7 !important;

      .el-table__expand-icon {
        display: none;
      }
    }

    &:not(.elv-rule-table-is-disabled) {
      &.elv-table__row--striped {
        background: #f9fafb;
      }

      &.is-hover-row {
        background: #f5f7fa;
      }
    }

    &.elv-rule-table-is-disabled {
      background: #f9fafb;
      opacity: 0.6;
    }

    .el-table__cell {
      padding: 0;

      .cell {
        padding: 0;
        min-height: 35px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #0e0f11;
        word-break: break-word;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        justify-content: center;

        .elv-automation-table-row__cell-action__title {
          color: #000;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-bottom: 4px;
        }

        .elv-automation-table-row__cell-action__label {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          white-space: nowrap;

          &.is-system {
            max-width: 300px;
            white-space: normal;
          }
        }

        .elv-automation-table-cell-conditions__item-is {
          margin: 0 6px;
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 11px;
          line-height: 14px;
          color: #636b75;

          span:nth-child(2) {
            margin-left: 4px;
          }
        }

        .elv-automation-table-row__cell-action__value {
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          margin-left: 4px;
          white-space: nowrap;
        }
      }

      &.is-right .cell {
        text-align: right;

        .elv-automation-table-row__cell-item {
          justify-content: flex-end;
        }
      }

      &.is-center .cell {
        text-align: center;

        .elv-automation-table-row__cell-item {
          justify-content: center;
        }
      }

      .elv-automation-table-row__cell-item {
        display: flex;
        align-items: center;
        padding: 0 10px;
        min-height: 42px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #edf0f3;

        &:last-child {
          border-bottom: 0px;
        }

        &.is-expanded {
          justify-content: center;

          svg {
            cursor: pointer;
          }
        }

        &.is-valuation {
          white-space: nowrap;

          .elv-automation-table-cell-conditions__item-value {
            font-size: 11px;
          }
        }

        &.is-counterparty {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 10px;
          box-sizing: border-box;

          > div {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
          }

          .elv-automation-table-cell-conditions__item-value {
            font-size: 11px;
          }
        }
      }

      &.none-padding {
        .cell {
          padding: 0px;
        }
      }
    }
  }

  .elv-automation-table-row__cell-index {
    color: #aaafb6;
    font-family: 'Barlow';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .elv-automation-table-row__cell-name {
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-automation-table-row__cell-data {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }

  .elv-automation-table-row__cell-action {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-automation-table-cell-action__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .elv-automation-table-row__cell-conditions {
    padding: 11px 0;
    display: flex;
    flex-direction: column;

    .elv-automation-table-cell-conditions__item {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .elv-automation-table-cell-conditions__item-type {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #0e0f11;
    }
  }

  .elv-automation-table-row__cell-actions {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #0e0f11;

    p {
      display: flex;
      align-items: center;
    }

    span {
      height: 18px;
      box-sizing: border-box;
      padding: 0 6px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      margin-left: 6px;
      color: #ffffff;
    }
  }

  .elv-automation-table-row__cell-reconciliation {
    display: flex;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    p {
      color: #0e0f11;
      margin-left: 4px;
      font-weight: 500;
    }
  }

  .elv-automation-table-row__cell-switch {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .elv-automation-table-row__cell-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    img {
      width: 18px;
      height: 18px;
      display: block;
      animation: loading-rotate 2s linear infinite;
    }

    svg {
      cursor: pointer;
      fill: #838d95;

      &.is-disabled {
        cursor: not-allowed !important;
        fill: #c4c9d1 !important;
      }

      &:hover {
        fill: #1e2024;
      }
    }
  }
}
</style>
