<template>
  <div class="elv-automation-rule-conditions">
    <el-select
      v-model="conditionData.type"
      popper-class="elv-automation-rule-condition-input-popper"
      class="elv-automation-rule-condition-type"
      :placeholder="t('common.selectCondition')"
      :disabled="props.index === 0 && props.activeTab === 'trade'"
      @change="onChangeConditionType"
    >
      <el-option
        v-for="(item, i) in conditionTypeOption"
        :key="i"
        :label="`${transformI18n(item.label)} ${
          item.value === 'AMOUNT_FC' ? `(${entityStore.entityDetail?.defaultCurrency})` : ''
        }`"
        :value="item.value"
        :disabled="disableConditionType(item.value)"
      />
    </el-select>

    <SingleChoiceSelect
      v-if="conditionData.type === 'DIRECTION'"
      v-model="conditionData.value"
      placeholder="Select direction"
      class="elv-automation-rule-condition-long-input"
      :prefix-text="t('common.is')"
      width="360px"
      :options="directionOptions"
      @change="onChangeCondition"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'ORIGIN'"
      v-model="conditionData.value"
      placeholder="Select origin"
      class="elv-automation-rule-condition-long-input"
      :prefix-text="t('common.is')"
      width="360px"
      :options="adjustmentOriginOptions"
      @change="onChangeCondition"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'POSITION_SIDE'"
      v-model="conditionData.value"
      placeholder="Select origin"
      class="elv-automation-rule-condition-long-input"
      :prefix-text="t('common.is')"
      width="360px"
      :options="positionOptions"
      @change="onChangeCondition"
    />

    <GroupMultipleChoiceSelect
      v-else-if="conditionData.type === 'TRANSACTION_TYPE'"
      ref="transactionSelectRef"
      v-model="conditionData.value"
      placeholder="Select transaction type"
      width="360px"
      split-string="_"
      :model-type="props.model"
      :max-collapse-tags="2"
      class="elv-automation-rule-condition-long-input"
      :group-option="transactionType"
      @change="onChangeTransactionType"
    />

    <SingleChoiceSelect
      v-else-if="['TRADE_TYPE', 'GAIN_LOSS_TYPE'].includes(conditionData.type)"
      v-model="conditionData.value"
      :placeholder="conditionData.type === 'TRADE_TYPE' ? 'Select a trade type' : 'Select a gain/loss type'"
      class="elv-automation-rule-condition-long-input"
      :prefix-text="t('common.is')"
      width="360px"
      :options="conditionData.type === 'TRADE_TYPE' ? tradeTypeOption : gainLossTypeOption"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'PLATFORM_TYPE'"
      v-model="conditionData.value"
      placeholder="Select platform type"
      class="elv-automation-rule-condition-long-input"
      width="360px"
      multiple
      clearable
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="3"
      :options="transactionType"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'PLATFORM'"
      v-model="conditionData.value.platformIds"
      placeholder="Select platform"
      class="elv-automation-rule-condition-long-input"
      width="360px"
      multiple
      clearable
      filterable
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="2"
      :options="platformList"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'CURRENCY'"
      v-model="conditionData.value"
      placeholder="Input currency code"
      class="elv-automation-rule-condition-long-input"
      width="360px"
      multiple
      clearable
      filterable
      remote
      remote-show-suffix
      reserve-keyword
      :loading="currencyLoading"
      :remote-method="remoteCurrencyMethod"
      collapse-tags
      :is-remote-icon="true"
      collapse-tags-tooltip
      :max-collapse-tags="2"
      :options="currencyOptions"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'ASSET'"
      v-model="conditionData.value"
      placeholder="Input symbol"
      class="elv-automation-rule-condition-long-input"
      width="360px"
      multiple
      clearable
      filterable
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="2"
      :options="derivateOptions"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <template v-else-if="['DATETIME', 'ENDING_DATE'].includes(conditionData.type)">
      <DateTimeCondition
        v-model:value="conditionData.value"
        v-model:operator="conditionData.operator"
        :active-tab="props.activeTab"
        @onChangeType="onChangeType"
      />
    </template>

    <template v-else-if="['AMOUNT', 'AMOUNT_FC'].includes(conditionData.type)">
      <AmountCondition
        v-model:value="conditionData.value"
        v-model:operator="conditionData.operator"
        :active-tab="props.activeTab"
        @onChangeType="onChangeType"
        @onChangeCondition="onChangeCondition"
      />
    </template>

    <ElvSelect
      v-else-if="conditionData.type === 'ENTITY_ACCOUNT'"
      v-model="conditionData.value.entityAccountIds"
      placeholder="Select account"
      class="elv-automation-rule-condition-long-input is-account"
      :class="{
        'has-deleted': conditionData.value.entityAccountIds.some((id: string) =>
          accountList.some((item: any) => item.value === id && item.isDeleted)
        )
      }"
      width="360px"
      multiple
      clearable
      filterable
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="2"
      :options="accountList"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'COUNTERPARTY'"
      v-model="conditionData.value.counterpartyIds"
      :placeholder="t('placeholder.enterNameForSearch')"
      class="elv-automation-rule-condition-long-input"
      width="360px"
      multiple
      clearable
      filterable
      remote
      remote-show-suffix
      reserve-keyword
      :loading="searchCounterpartyLoading"
      :remote-method="remoteCounterpartyMethod"
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="2"
      :options="counterpartyList"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <SingleChoiceSelect
      v-else-if="conditionData.type === 'COUNTERPARTY_TAG'"
      v-model="conditionData.value.counterpartyTagIds"
      placeholder="Select counterparty tag"
      class="elv-automation-rule-condition-long-input"
      width="360px"
      multiple
      clearable
      collapse-tags
      collapse-tags-tooltip
      :max-collapse-tags="3"
      :options="counterpartTagOptions"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <CounterpartyAccountCondition
      v-else-if="conditionData.type === 'COUNTERPARTY_IDENTITY'"
      v-model="conditionData.value"
      :options="counterpartyAccountOptions"
      @onChangeCondition="onChangeCondition"
    />

    <template v-if="conditionData.type === 'MEMO'">
      <MemoCondition
        v-model:value="conditionData.value"
        v-model:operator="conditionData.operator"
        @onChangeType="onChangeType"
        @onChangeCondition="onChangeCondition"
      />
    </template>

    <div class="elv-automation-rule-condition-operating">
      <SvgIcon
        v-if="props.index !== 0 || props.conditionList.length > 1"
        name="sources-delete"
        width="18"
        height="18"
        :class="{ 'is-disabled': props.index === 0 && props.activeTab === 'trade' }"
        class="elv-automation-rule-condition-operating__delete"
        @click="onDeleteCondition"
      />
      <el-tooltip
        ref="elvTableTipsRef"
        :show-arrow="false"
        effect="light"
        placement="top"
        popper-class="elv-table-tips"
        overlay-classname="elv-table-tips"
        :show-after="500"
      >
        <SvgIcon
          name="source-add"
          width="18"
          height="18"
          class="elv-automation-rule-condition-operating__add"
          @click="onAddCondition"
        />
        <template #content>
          <div class="elv-table-tips-content">
            {{ t('message.addCountConditions', { count: conditionTypeOption.length }) }}
          </div>
        </template>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ConfigApi from '@/api/ConfigApi'
import AccountsApi from '@/api/AccountsApi'
import MemoCondition from './MemoCondition.vue'
import { $t, transformI18n } from '@/i18n/index'
import { CurrencyItemType } from '#/ReportsTypes'
import AmountCondition from './AmountCondition.vue'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import DateTimeCondition from './DateTimeCondition.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { adjustmentOriginOptions } from '@/config/valuation'
import { useAccountStore } from '@/stores/modules/accounts'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'
import CounterpartyAccountCondition from './CounterpartyAccountCondition.vue'
import { tradeType, gainLossType, positionOptions, transactionType } from '@/config/index'
import GroupMultipleChoiceSelect from '@/components/Base/GroupMultipleChoiceSelect.vue'
import { pick, isEmpty, isObject, isArray, uniqBy, includes, cloneDeep, difference, capitalize } from 'lodash-es'

const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  condition: {
    type: Object,
    required: true
  },
  conditionList: {
    type: Array,
    required: true
  },
  model: {
    type: String,
    required: true
  },
  currencyList: {
    type: Array as () => CurrencyItemType[],
    required: true
  },
  derivateOptions: {
    type: Array as () => ElvSelectOptionType[],
    default: () => {
      return []
    }
  },
  activeTab: {
    type: String,
    default: ''
  },
  derivativeType: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const counterpartyAccountOptions: any = ref([])
const counterpartyOptions: any = ref([])
const originCounterpartyOptions: any = ref([])

const emit = defineEmits(['onConditionChange', 'addCondition'])

const addressData = ref()
const currencyLoading = ref(false)
const searchCounterpartyLoading = ref(false)
const transactionSelectRef = ref()
const currenciesData = ref<CurrencyItemType[]>([])

const conditionData: any = ref({ operator: 'EQ', type: '', value: '' })

const directionOptions = reactive([
  { label: t('report.inflow'), value: 'IN' },
  { label: t('report.outflow'), value: 'OUT' }
])

const conditionTypeOption = computed(() => {
  switch (props.activeTab) {
    case 'transfer':
      return [
        { label: $t('common.direction'), value: 'DIRECTION' },
        { label: $t('common.type'), value: 'TRANSACTION_TYPE' },
        { label: $t('common.platformType'), value: 'PLATFORM_TYPE' },
        { label: $t('common.platform'), value: 'PLATFORM' },
        { label: $t('common.currency'), value: 'CURRENCY' },
        { label: $t('report.Datetime'), value: 'DATETIME' },
        { label: $t('common.amount'), value: 'AMOUNT' },
        { label: $t('common.amount'), value: 'AMOUNT_FC' },
        { label: $t('common.account'), value: 'ENTITY_ACCOUNT' },
        { label: $t('common.counterparty'), value: 'COUNTERPARTY' },
        { label: $t('common.counterpartyTag'), value: 'COUNTERPARTY_TAG' },
        { label: $t('common.counterpartyAccount'), value: 'COUNTERPARTY_IDENTITY' },
        { label: $t('report.memo'), value: 'MEMO' }
      ]
    case 'trade':
      return [
        { label: $t('common.type'), value: 'TRADE_TYPE' },
        { label: $t('common.platformType'), value: 'PLATFORM_TYPE' },
        { label: $t('common.platform'), value: 'PLATFORM' },
        { label: $t('report.Base Asset'), value: 'ASSET' },
        { label: $t('common.account'), value: 'ENTITY_ACCOUNT' },
        { label: $t('report.positionAndSide'), value: 'POSITION_SIDE' },
        { label: $t('report.Datetime'), value: 'DATETIME' },
        { label: $t('report.memo'), value: 'MEMO' }
      ]
    case 'gainLoss':
      return [
        { label: $t('common.type'), value: 'GAIN_LOSS_TYPE' },
        { label: $t('common.platformType'), value: 'PLATFORM_TYPE' },
        { label: $t('common.platform'), value: 'PLATFORM' },
        { label: $t('common.account'), value: 'ENTITY_ACCOUNT' },
        { label: $t('report.Base Asset'), value: 'ASSET' },
        { label: $t('report.positionAndSide'), value: 'POSITION_SIDE' },
        { label: $t('common.amount'), value: 'AMOUNT' },
        { label: $t('common.amount'), value: 'AMOUNT_FC' },
        { label: $t('report.Datetime'), value: 'DATETIME' },
        { label: $t('report.memo'), value: 'MEMO' }
      ]
    case 'derivativeAdjustment':
      if (props.derivativeType === '') {
        return [
          { label: $t('report.Datetime'), value: 'ENDING_DATE' },
          { label: $t('common.account'), value: 'ENTITY_ACCOUNT' }
        ]
      }
      if (props.derivativeType === 'VALIDATOR') {
        return [
          { label: $t('report.Datetime'), value: 'ENDING_DATE' },
          { label: $t('common.account'), value: 'ENTITY_ACCOUNT' },
          { label: $t('valuation.validator.rewardsChange'), value: 'AMOUNT' },
          { label: $t('valuation.validator.rewardsChange'), value: 'AMOUNT_FC' }
        ]
      }
      return [
        { label: $t('common.origin'), value: 'ORIGIN' },
        { label: $t('report.Datetime'), value: 'ENDING_DATE' },
        { label: $t('common.account'), value: 'ENTITY_ACCOUNT' },
        { label: $t('common.symbol'), value: 'ASSET' },
        { label: $t('report.positionAndSide'), value: 'POSITION_SIDE' },
        { label: $t('valuation.assets.marginAsset'), value: 'CURRENCY' },
        { label: $t('valuation.future.unrealizedAndChange'), value: 'AMOUNT' },
        { label: $t('valuation.future.unrealizedAndChange'), value: 'AMOUNT_FC' }
      ]

    case 'counterpartyTransformer':
      return [
        { label: $t('common.platformType'), value: 'PLATFORM_TYPE' },
        { label: $t('common.platform'), value: 'PLATFORM' },
        { label: $t('common.account'), value: 'ENTITY_ACCOUNT' },
        { label: $t('report.Datetime'), value: 'DATETIME' }
      ]
    default:
      return []
  }
})

const tradeTypeOption = computed(() => {
  return tradeType.map((item: any) => {
    return {
      label: item.label,
      value: item.value
    }
  })
})

const gainLossTypeOption = computed(() => {
  return gainLossType
    .filter((item) => ['OPTION_REALIZED', 'FUTURE_REALIZED'].includes(item.value))
    .map((item: any) => {
      return {
        label: item.label,
        value: item.value
      }
    })
})

const conditionTypeWidth = computed(() => {
  let width = '100%'
  if (!conditionData.value.type && props.index === 0 && props.conditionList.length === 1) {
    return '754px'
  }
  if (!conditionData.value.type) {
    return '734px'
  }
  const wide = ['DATETIME', 'AMOUNT', 'AMOUNT_FC', 'MEMO', 'ENDING_DATE']
  const wider = [
    'DIRECTION',
    'POSITION_SIDE',
    'ORIGIN',
    'ASSET',
    'TRANSACTION_TYPE',
    'TRADE_TYPE',
    'GAIN_LOSS_TYPE',
    'PLATFORM_TYPE',
    'ENTITY_ACCOUNT',
    'PLATFORM',
    'CURRENCY',
    'COUNTERPARTY',
    'COUNTERPARTY_TAG',
    'COUNTERPARTY_IDENTITY'
  ]
  if (includes(wide, conditionData.value.type)) {
    if (['AMOUNT', 'AMOUNT_FC'].includes(conditionData.value.type) && conditionData.value.operator === 'BETWEEN') {
      width = '176px'
    } else {
      width = '237px'
    }
  }
  if (includes(wider, conditionData.value.type)) {
    width = '365px'
  }
  return width
})

const platformList = computed(() => {
  return entityStore.platformList.map((item: any) => {
    return {
      value: item.platformId,
      label: item.name,
      icon: item.logo
    }
  })
})

const accountList = computed(() => {
  const list =
    props.model === 'edit'
      ? accountStore.accountList
      : accountStore.accountList.filter((item: any) => item.status === 'NORMAL')
  return list.map((item: any) => {
    return {
      value: item.entityAccountId,
      label: item.name,
      icon: item.platform?.logo,
      alt: item.platform?.name,
      isDeleted: item.status !== 'NORMAL'
    }
  })
})

const currencyOptions = computed(() => {
  return currenciesData.value.map((item: any) => {
    return {
      value: item?.symbol,
      id: item?.underlyingCurrencyId,
      label: item?.showSymbol,
      icon: item?.logo,
      alt: item?.name
    }
  })
})

const counterpartyList = computed(() => {
  return counterpartyOptions.value.map((item: any) => {
    return {
      value: item.counterpartyId,
      label: item.name
    }
  })
})
const counterpartTagOptions = computed(() => {
  return accountStore.counterpartTagList.map((item: any) => {
    return {
      value: item.counterpartyTagId,
      label: item.name
    }
  })
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const disableConditionType = computed(() => {
  return (type: string) => {
    const types = props.conditionList.map((item: any) => item.type)
    return includes(types, type)
  }
})

const onChangeCondition = () => {
  if (!conditionData.value.operator) conditionData.value.operator = 'EQ'
  emit('onConditionChange', props.index, unref(conditionData.value))
}

const onChangeTransactionType = () => {
  const { onChangeGroupSelectData } = transactionSelectRef.value
  const differenceType: any =
    difference(conditionData.value.value, transactionSelectRef.value?.groupSelectData.lastTimeData)?.[0] ??
    difference(transactionSelectRef.value?.groupSelectData.lastTimeData, conditionData.value.value)?.[0] ??
    ''
  const type = differenceType ? capitalize(differenceType.split('_')[0]) : ''
  onChangeGroupSelectData?.('lastTimeData', conditionData.value.value)
  const typeList = transactionType.find((item: any) => item.type === type)?.list
  if (typeList) {
    const typeDataLength = conditionData.value.value.filter((i: any) => capitalize(i.split('_')[0]) === type).length
    onChangeGroupSelectData?.(`isIndeterminate${type}`, !!(typeDataLength > 0 && typeDataLength < typeList.length))
    onChangeGroupSelectData?.(`checkAll${type}`, typeDataLength === typeList.length)
  } else {
    onChangeGroupSelectData?.(`isIndeterminate${type}`, false)
    onChangeGroupSelectData?.(`checkAll${type}`, false)
  }
  onChangeCondition()
}

const onDeleteCondition = () => {
  if (props.index === 0 && props.activeTab === 'trade') return
  emit('onConditionChange', props.index, conditionData.value, 'delete')
}

const remoteCurrencyMethod = async (query: string) => {
  if (query) {
    try {
      currencyLoading.value = true
      const params = {
        recommend: false,
        entityId: entityId.value,
        keywords: [query]
      }
      const { data } = await ConfigApi.searchCurrencyList(params)
      currenciesData.value = data
    } catch (error) {
      currenciesData.value = []
    } finally {
      currencyLoading.value = false
    }
  } else {
    currenciesData.value = props.currencyList
  }
}

const remoteCounterpartyMethod = async (query: string) => {
  if (query) {
    try {
      searchCounterpartyLoading.value = true
      const params = {
        keywords: query,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list
    } catch (error) {
      console.log(error)
    } finally {
      searchCounterpartyLoading.value = false
    }
  } else {
    counterpartyOptions.value = originCounterpartyOptions.value
  }
}

const onAddCondition = () => {
  if (props.activeTab === 'derivativeAdjustment' && props.conditionList.length > 7) return
  emit('addCondition', props.index)
}

const onChangeType = () => {
  if (['AMOUNT', 'AMOUNT_FC'].includes(conditionData.value.type)) {
    if (conditionData.value.operator === 'BETWEEN') {
      conditionData.value.value = ['', '']
    } else {
      conditionData.value.value = ['']
    }
  } else {
    conditionData.value.value = ''
  }
  addressData.value = {}
  conditionData.value = pick(conditionData.value, ['operator', 'value', 'type'])
  onChangeCondition()
}

const onChangeConditionType = (condition: any) => {
  conditionData.value = {
    type: condition,
    operator: 'IN',
    value: ''
  }
  switch (condition) {
    case 'PLATFORM':
      conditionData.value.value = {
        platformIds: []
      }
      break
    case 'ENTITY_ACCOUNT':
      conditionData.value.value = {
        entityAccountIds: []
      }
      break
    case 'COUNTERPARTY':
      conditionData.value.value = {
        counterpartyIds: []
      }
      break
    case 'COUNTERPARTY_TAG':
      conditionData.value.value = {
        counterpartyTagIds: []
      }
      break
    case 'AMOUNT':
    case 'AMOUNT_FC':
      conditionData.value.value = ['']
      conditionData.value.operator = ''
      break
    case 'TRANSACTION_TYPE':
    case 'PLATFORM_TYPE':
    case 'CURRENCY':
    case 'COUNTERPARTY_IDENTITY':
      conditionData.value.value = []
      conditionData.value.operator = 'IN'
      break
    case 'DATETIME':
    case 'ENDING_DATE':
      conditionData.value.operator = 'ON'
      break
    case 'DIRECTION':
    case 'GAIN_LOSS_TYPE':
    case 'TRADE_TYPE':
    case 'POSITION_SIDE':
    case 'ORIGIN':
      conditionData.value.operator = 'EQ'
      break
    case 'MEMO':
      conditionData.value.operator = ''
      break
    default:
      break
  }
  onChangeCondition()
}

watchEffect(
  () => {
    if (!isEmpty(props.condition) && props.model === 'edit') {
      conditionData.value = props.condition
      if (props.condition.type === 'COUNTERPARTY_IDENTITY' && props.condition.value.length) {
        counterpartyAccountOptions.value = props.condition.value.map((item: any) => {
          return {
            value: item,
            label: item
          }
        })
      }
      if (props.condition.type === 'PLATFORM' && props.condition.value.length) {
        conditionData.value.value = {
          platformIds: props.condition.value.map((item: any) => item.platformId)
        }
      }
      if (props.condition.type === 'CURRENCY' && props.condition.value.length) {
        if (props.condition.value.every((i: any) => isObject(i))) {
          conditionData.value.value = props.condition.value.map((item: any) => item?.symbol)
          const list = [...cloneDeep(currenciesData.value), ...cloneDeep(props.condition.value)]
          currenciesData.value = uniqBy(list, 'underlyingCurrencyId')
        }
      }
      if (props.condition.type === 'ENTITY_ACCOUNT' && props.condition.value.length) {
        conditionData.value.value = {
          entityAccountIds: props.condition.value.map((item: any) => item.entityAccountId)
        }
      }
      if (props.condition.type === 'COUNTERPARTY' && props.condition.value.length) {
        if (isArray(props.condition.value) && props.condition.value?.every((i: any) => i?.type)) {
          const list = [
            ...cloneDeep(props.condition.value),
            ...cloneDeep(originCounterpartyOptions.value),
            ...cloneDeep(accountStore.counterpartList.list)
          ]
          originCounterpartyOptions.value = uniqBy(list, 'counterpartyId')
          counterpartyOptions.value = uniqBy(list, 'counterpartyId')
        }
        conditionData.value.value = {
          counterpartyIds: props.condition.value.map((item: any) => item.counterpartyId)
        }
      }
      if (props.condition.type === 'COUNTERPARTY_TAG' && props.condition.value.length) {
        conditionData.value.value = {
          counterpartyTagIds: props.condition.value.map((item: any) => item.counterpartyTagId)
        }
      }
      if (['AMOUNT', 'AMOUNT_FC'].includes(props.condition.type) && props.condition.value === '') {
        conditionData.value.value = props.condition.type === 'BETWEEN' ? ['', ''] : [props.condition.value?.[0] ?? '']
      }
      // console.log(conditionData.value)
      if (props.condition.type === 'ASSET' && props.condition.value.length) {
        conditionData.value.value = props.condition.value.map((item: any) => item?.assetId ?? item ?? '')
      }
    }
    if (props.model === 'add' && props.activeTab === 'trade' && props.index === 0) {
      conditionData.value.type = 'TRADE_TYPE'
    }
  },
  { flush: 'post' }
)

watch(
  () => props.derivativeType,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      conditionData.value = {
        operator: 'EQ',
        type: '',
        value: ''
      }
    }
  },
  { immediate: true }
)

watch(
  () => accountStore.counterpartList,
  () => {
    if (accountStore.counterpartList.total) {
      const list = [...cloneDeep(originCounterpartyOptions.value), ...cloneDeep(accountStore.counterpartList.list)]
      originCounterpartyOptions.value = uniqBy(list, 'counterpartyId')
      counterpartyOptions.value = uniqBy(list, 'counterpartyId')
    }
  },
  { immediate: true }
)

watchEffect(() => {
  if (props.currencyList.length) {
    currenciesData.value = props.currencyList
  }
})
</script>

<style lang="scss">
.elv-automation-rule-conditions {
  display: flex;
  position: relative;
  margin-bottom: 8px;

  .el-select {
    width: fit-content;

    .el-select__prefix {
      color: #aaafb6;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-right: 3px;
    }
  }

  .elv-automation-rule-condition-type {
    .el-select__wrapper {
      width: v-bind('conditionTypeWidth') !important;
    }
  }

  .elv-automation-rule-condition-identity-input {
    width: 365px !important;
    margin-left: 8px;

    .el-input__wrapper {
      height: 44px;
      box-sizing: border-box;

      .el-input__prefix {
        margin-right: 10px;
      }
    }
  }

  .elv-automation-rule-condition-long-input {
    margin-left: 8px;

    .el-select__wrapper,
    .el-input {
      width: 360px !important;
    }

    &.is-account.has-deleted {
      position: relative;
      margin-bottom: 24px;

      .el-select__wrapper {
        border: 1px solid #7e4a15;
        background: #faeee6;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
      }

      &::after {
        content: 'Please remove deleted account';
        position: absolute;
        left: 0;
        bottom: -24px;
        color: #7e4a15;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .el-select__selected-item .el-tag .el-select__tags-text span {
      max-width: 72px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .elv-automation-rule-condition-short-input {
    width: 239.33px !important;

    margin-left: 8px;

    .el-input,
    .el-select__wrapper {
      width: 239.33px !important;
    }

    &.el-input {
      .el-input__wrapper {
        height: 44px;
        box-sizing: border-box;
        width: 239.33px !important;
        max-width: 239.33px !important;
      }
    }
  }

  .elv-automation-rule-condition-shorter-input {
    width: 176px !important;
    margin-left: 8px;

    .el-input,
    .el-select__wrapper {
      width: 176px !important;
    }

    &.el-input {
      height: 44px !important;

      .el-input__wrapper {
        height: 100%;
        box-sizing: border-box;
        width: 176px !important;
        max-width: 176px !important;
      }
    }
  }

  .elv-automation-rule-drawer-address-select {
    box-sizing: border-box;
    height: 44px;
    width: 239.33px;
    padding: 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: relative;
    margin-left: 8px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: border, box-shadow;

    .elv-automation-rule-drawer-address-select__is {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #838d95;
      margin-right: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #aaafb6;
        margin-bottom: 8px;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0e0f11;
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

      svg {
        fill: #5e85eb;
      }
    }

    svg {
      position: absolute;
      right: 12px;
      fill: #838d95;
      transition: transform 0.3s;
    }

    .elv-automation-rule-drawer-time-value {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .elv-automation-rule-drawer-currency-select {
    box-sizing: border-box;
    height: 36px;
    width: 360px;
    padding: 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: relative;
    margin-left: 8px;

    .elv-automation-rule-drawer-address-select__is {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #838d95;
      margin-right: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #aaafb6;
        margin-bottom: 8px;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0e0f11;
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

      svg {
        fill: #5e85eb;
      }
    }

    svg {
      position: absolute;
      right: 12px;
      bottom: 8px;
      fill: #838d95;
      transition: transform 0.3s;
    }
  }

  .elv-automation-rule-condition-operating {
    position: absolute;
    right: 0px;
    top: 13px;
    display: flex;
    align-items: center;
    margin-left: 8px;

    svg {
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .elv-automation-rule-condition-operating__delete {
      margin-right: 8px;
      fill: #838d95;

      &.is-disabled {
        fill: #c4c9d1;
        cursor: not-allowed;
      }
    }

    .elv-automation-rule-condition-operating__add {
      fill: #838d95;

      &.is-disabled {
        fill: #c4c9d1;
        cursor: not-allowed;
      }
    }
  }
}

.elv-automation-rule-condition-input-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    &:not(.is-disabled) &.hover {
      background: #f9fafb;
    }

    &.is-disabled {
      color: #a8abb2;
    }

    &.is-selected:after {
      display: none;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        width: 32px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
