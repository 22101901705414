<template>
  <div class="elv-automation-rule-conditions is-columns">
    <el-select
      v-model="conditionData.column"
      filterable
      popper-class="elv-automation-rule-condition-input-popper"
      class="elv-automation-rule-condition-column"
      :placeholder="t('common.selectAColumn')"
      :disabled="props.conditionTypeDisabled"
      @change="onChangeColumn"
    >
      <el-option
        v-for="(item, i) in props.columnOptions"
        :key="i"
        :label="item as string"
        :value="i"
        :disabled="disableColumn(i)"
      />
    </el-select>

    <el-select
      v-if="conditionData?.column !== ''"
      v-model="conditionData.columnType"
      popper-class="elv-automation-rule-condition-input-popper"
      class="elv-automation-rule-condition-column-type"
      :placeholder="t('common.selectTheDataType')"
      @change="onChangeColumnType"
    >
      <el-option
        v-for="(item, i) in columnTypeOption"
        :key="i"
        :label="transformI18n(item.label)"
        :value="item.value"
      />
    </el-select>

    <SingleChoiceSelect
      v-if="conditionData.columnType === 'CURRENCY_TYPE'"
      v-model="conditionData.type"
      placeholder="Select"
      class="elv-automation-rule-condition-short-input"
      :options="currencyOptions"
      :popper-append-to-body="false"
      @change="onChangeCondition"
    />

    <template v-else-if="conditionData.columnType === 'DATETIME'">
      <DateTimeCondition
        v-model:value="conditionData.value"
        v-model:operator="conditionData.operator"
        :active-tab="'businessData'"
        @onChangeType="onChangeType"
      />
    </template>

    <template v-else-if="conditionData.columnType === 'TEXT'">
      <TextCondition
        v-model:value="conditionData.value"
        v-model:operator="conditionData.operator"
        @onChangeType="onChangeType"
        @onChangeCondition="onChangeCondition"
      />
    </template>

    <template v-else-if="conditionData.columnType === 'NUMBER'">
      <AmountCondition
        v-model:value="conditionData.value"
        v-model:operator="conditionData.operator"
        :active-tab="'businessData'"
        @onChangeType="onChangeType"
        @onChangeCondition="onChangeCondition"
      />
    </template>

    <div class="elv-automation-rule-condition-operating">
      <SvgIcon
        v-if="props.index !== 0 || props.conditionList.length > 1"
        name="sources-delete"
        width="18"
        height="18"
        class="elv-automation-rule-condition-operating__delete"
        @click="onDeleteCondition"
      />
      <el-tooltip
        ref="elvTableTipsRef"
        :show-arrow="false"
        effect="light"
        placement="top"
        :disabled="!props.columnOptions.length"
        popper-class="elv-table-tips"
        overlay-classname="elv-table-tips"
        :show-after="500"
      >
        <SvgIcon
          name="source-add"
          width="18"
          height="18"
          class="elv-automation-rule-condition-operating__add"
          :class="{ 'is-disabled': props.conditionTypeDisabled }"
          @click="onAddCondition"
        />
        <template #content>
          <div class="elv-table-tips-content">
            {{ t('message.addCountConditions', { count: props.columnOptions.length }) }}
          </div>
        </template>
      </el-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { $t, transformI18n } from '@/i18n/index'
import { CurrencyItemType } from '#/ReportsTypes'
import TextCondition from '../Conditions/TextCondition.vue'
import AmountCondition from '../Conditions/AmountCondition.vue'
import DateTimeCondition from '../Conditions/DateTimeCondition.vue'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'
import { pick, isEmpty, isObject, uniqBy, includes, cloneDeep } from 'lodash-es'

const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  condition: {
    type: Object,
    required: true
  },
  conditionList: {
    type: Array,
    required: true
  },
  model: {
    type: String,
    required: true
  },
  currencyList: {
    type: Array<CurrencyItemType>,
    required: true
  },
  columnOptions: {
    type: Array,
    required: true
  },
  conditionTypeDisabled: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()

const emit = defineEmits(['onConditionChange', 'addCondition'])

const currenciesData = ref<CurrencyItemType[]>([])

const conditionData: any = ref({ operator: 'EQ', column: '', columnType: '', type: '', value: '' })

const columnTypeOption = reactive([
  { label: $t('common.currency'), value: 'CURRENCY_TYPE' },
  { label: $t('report.Datetime'), value: 'DATETIME' },
  { label: $t('common.amount'), value: 'NUMBER' },
  { label: $t('common.text'), value: 'TEXT' }
])

const columnWidth = computed(() => {
  let width = '100%'
  if (conditionData.value.column === '' && props.index === 0 && props.conditionList.length === 1) {
    return '754px'
  }
  if (conditionData.value.column === '') {
    return '734px'
  }
  if (!conditionData.value.columnType) return '365px'
  const wide = ['DATETIME', 'NUMBER', 'TEXT']
  const wider = ['CURRENCY_TYPE']
  if (includes(wide, conditionData.value.columnType)) {
    if (['NUMBER'].includes(conditionData.value.columnType) && conditionData.value.operator === 'BETWEEN') {
      width = '140.4px'
    } else {
      width = '177.5px'
    }
  }
  if (includes(wider, conditionData.value.columnType)) {
    width = '239.33px'
  }
  return width
})

const columnTypeWidth = computed(() => {
  let width = '100%'
  if (!conditionData.value.columnType) {
    return '360px'
  }
  const wide = ['DATETIME', 'NUMBER', 'TEXT']
  const wider = ['CURRENCY_TYPE']
  if (includes(wide, conditionData.value.columnType)) {
    if (['NUMBER'].includes(conditionData.value.columnType) && conditionData.value.operator === 'BETWEEN') {
      width = '140.4px'
    } else {
      width = '177.5px'
    }
  }
  if (includes(wider, conditionData.value.columnType)) {
    width = '239.33px'
  }
  return width
})

const currencyOptions = computed(() => {
  return [
    {
      label: 'is Fiat',
      value: 'CURRENCY_FIAT'
    },
    {
      label: 'is Crypto Assets',
      value: 'CURRENCY_CRYPTO'
    }
  ]
})

const disableColumn = computed(() => {
  return (column: number) => {
    const columns = props.conditionList.map((item: any) => item.column)
    return includes(columns, column)
  }
})

const onChangeCondition = () => {
  if (!conditionData.value.operator) conditionData.value.operator = 'EQ'
  emit('onConditionChange', props.index, unref(conditionData.value))
}

const onDeleteCondition = () => {
  emit('onConditionChange', props.index, conditionData.value, 'delete')
}

const onAddCondition = () => {
  if (props.conditionTypeDisabled) return
  emit('addCondition', props.index)
}

const onChangeType = () => {
  if (['NUMBER', 'TEXT'].includes(conditionData.value.columnType)) {
    if (conditionData.value.operator === 'BETWEEN') {
      conditionData.value.value = ['', '']
    } else if (conditionData.value.columnType === 'TEXT') {
      conditionData.value.value = []
    } else {
      conditionData.value.value = ['']
    }
  } else {
    conditionData.value.value = ''
  }
  conditionData.value = pick(conditionData.value, ['operator', 'value', 'type', 'column', 'columnType'])
  onChangeCondition()
}

const onChangeColumn = (column: number) => {
  conditionData.value = {
    column,
    columnType: '',
    type: '',
    operator: 'IN',
    value: ''
  }
  onChangeCondition()
}

const onChangeColumnType = (columnType: string) => {
  conditionData.value = {
    column: conditionData.value.column,
    columnType,
    type: '',
    operator: 'IN',
    value: ''
  }
  switch (columnType) {
    case 'NUMBER':
      conditionData.value.value = ['']
      conditionData.value.operator = ''
      break
    case 'DATETIME':
      conditionData.value.operator = 'ON'
      break
    case 'TEXT':
      conditionData.value.operator = 'EQ'
      conditionData.value.value = ['']
      break
    case 'CURRENCY_TYPE':
      conditionData.value.operator = 'EQ'
      break
    default:
      break
  }
  onChangeCondition()
}

const onResetColumn = () => {
  conditionData.value = {
    column: '',
    columnType: '',
    type: '',
    operator: 'IN',
    value: ''
  }
}

defineExpose({
  onResetColumn
})

watchEffect(() => {
  if (!isEmpty(props.condition) && props.model === 'edit') {
    conditionData.value = props.condition
    if (props.condition.type === 'CURRENCY_TYPE' && props.condition.value.length) {
      if (props.condition.value.every((i: any) => isObject(i))) {
        conditionData.value.value = props.condition.value.map((item: any) => item?.symbol)
        const list = [...cloneDeep(currenciesData.value), ...cloneDeep(props.condition.value)]
        currenciesData.value = uniqBy(list, 'underlyingCurrencyId')
      }
    }
    if (['AMOUNT', 'AMOUNT_FC'].includes(props.condition.type) && props.condition.value === '') {
      conditionData.value.value = props.condition.type === 'BETWEEN' ? ['', ''] : ['']
    }
  }
})

watchEffect(() => {
  if (props.currencyList.length) {
    currenciesData.value = props.currencyList
  }
})
</script>

<style lang="scss">
.elv-automation-rule-conditions.is-columns {
  display: flex;
  position: relative;
  margin-bottom: 8px;

  .el-select {
    width: fit-content;

    .el-select__prefix {
      color: #aaafb6;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-right: 3px;
    }
  }

  .elv-automation-rule-condition-column {
    .el-select__wrapper {
      width: v-bind('columnWidth') !important;
    }
  }

  .elv-automation-rule-condition-column-type {
    margin-left: 8px;

    .el-select__wrapper {
      width: v-bind('columnTypeWidth') !important;
    }
  }

  .elv-automation-rule-condition-identity-input {
    width: 365px !important;
    margin-left: 8px;

    .el-input__wrapper {
      height: 44px;
      box-sizing: border-box;

      .el-input__prefix {
        margin-right: 10px;
      }
    }
  }

  .elv-automation-rule-condition-long-input {
    margin-left: 8px;

    .el-select__wrapper,
    .el-input {
      width: 360px !important;
    }
  }

  .elv-automation-rule-condition-short-input {
    width: 239.33px !important;

    margin-left: 8px;

    .el-input,
    .el-select__wrapper {
      width: 239.33px !important;
    }

    &.el-input {
      .el-input__wrapper {
        height: 44px;
        box-sizing: border-box;
        width: 239.33px !important;
        max-width: 239.33px !important;
      }
    }
  }

  .elv-automation-rule-condition-shorter-input {
    width: 177.5px !important;
    margin-left: 8px;

    .el-input,
    .el-select__wrapper {
      width: 177.5px !important;
    }

    &.el-input {
      height: 44px !important;

      .el-input__wrapper {
        height: 100%;
        box-sizing: border-box;
        width: 177.5px !important;
        max-width: 177.5px !important;
      }
    }
  }

  .elv-automation-rule-condition-shortest-input {
    width: 140.4px !important;
    margin-left: 8px;

    .el-input,
    .el-select__wrapper {
      width: 140.4px !important;
    }

    &.el-input {
      height: 44px !important;

      .el-input__wrapper {
        height: 100%;
        box-sizing: border-box;
        width: 140.4px !important;
        max-width: 140.4px !important;
      }
    }
  }

  .elv-automation-rule-drawer-address-select {
    box-sizing: border-box;
    height: 44px;
    width: 239.33px;
    padding: 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: relative;
    margin-left: 8px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition-property: border, box-shadow;

    &.is-shorter {
      width: 177.5px;
    }

    .elv-automation-rule-drawer-address-select__is {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #838d95;
      margin-right: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #aaafb6;
        margin-bottom: 8px;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0e0f11;
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

      svg {
        fill: #5e85eb;
      }
    }

    svg {
      position: absolute;
      right: 12px;
      fill: #838d95;
      transition: transform 0.3s;
    }

    .elv-automation-rule-drawer-time-value {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;

      > div {
        max-width: 130px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        span {
          white-space: nowrap;
        }
      }
    }
  }

  .elv-automation-rule-drawer-currency-select {
    box-sizing: border-box;
    height: 36px;
    width: 360px;
    padding: 4px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    position: relative;
    margin-left: 8px;

    .elv-automation-rule-drawer-address-select__is {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      color: #838d95;
      margin-right: 10px;
    }

    > div {
      display: flex;
      flex-direction: column;

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: #aaafb6;
        margin-bottom: 8px;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #0e0f11;
      }
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

      svg {
        fill: #5e85eb;
      }
    }

    svg {
      position: absolute;
      right: 12px;
      bottom: 8px;
      fill: #838d95;
      transition: transform 0.3s;
    }
  }

  .elv-automation-rule-condition-operating {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    margin-left: 8px;

    svg {
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .elv-automation-rule-condition-operating__delete {
      margin-right: 8px;
      fill: #838d95;
    }

    .elv-automation-rule-condition-operating__add {
      fill: #838d95;

      &.is-disabled {
        fill: #c4c9d1;
        cursor: not-allowed;
      }
    }
  }
}

.elv-automation-rule-condition-input-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;

    &:not(.is-disabled) &.hover {
      background: #f9fafb;
    }

    &.is-disabled {
      color: #a8abb2;
    }

    &.is-selected:after {
      display: none;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        width: 32px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
